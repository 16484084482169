<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>职位管理</BreadcrumbItem>
                <BreadcrumbItem>共享池</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem class="ivu-form-item-xl">
                    <Input v-model="filter.keywords" placeholder="输入职位名称、客户名称、联系人、顾问、职位ID" clearable/>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.type" placeholder="选择职位类型" filterable clearable>
                        <Option v-for="item in typeList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.level" placeholder="选择级别" filterable clearable>
                        <Option v-for="item in levelList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.status" placeholder="选择状态" filterable clearable>
                        <Option v-for="item in statusList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading">
                    <template slot-scope="{ row, index }" slot="action">
                        <Dropdown transfer>
                            <a href="javascript:void(0)">操作<Icon type="ios-arrow-down"></Icon></a>
                            <DropdownMenu class="table-dropdown" slot="list">
                                <DropdownItem v-if="permissionButton.view" @click.native="handleView(row, index)">详情</DropdownItem>
                                <DropdownItem v-if="permissionButton.edit && row.statusName === '进行中'" @click.native="handleEdit(row, index)">编辑</DropdownItem>
                                <DropdownItem v-if="permissionButton.mark && row.statusName === '进行中'" @click.native="handleMark(row, index)">标记</DropdownItem>
                                <DropdownItem v-if="permissionButton.join && row.statusName === '进行中' && (row.consultantIds !== null ? row.consultantIds.split(',').length < 10 : true) && row.owner === null" @click.native="handleJoin(row, index)">加入</DropdownItem>
                                <DropdownItem v-if="permissionButton.manage && row.statusName === '进行中'" @click.native="handleShare(row, index)">共享管理</DropdownItem>
                                <DropdownItem class="table-delete" v-if="permissionButton.del" @click.native="handleDel(row, index)">删除</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
        <view-position v-model="showViewModel" :position="position"></view-position>
        <mark-position v-model="showMarkModel" :position="position"
                       v-on:getSharePosition="getSharePosition"></mark-position>
        <share-position v-model="showShareModel" :position="position" v-on:getSharePosition="getSharePosition"></share-position>
        <edit-position v-model="showEditModel" :id="id" :custId="custId" v-on:getPosition="getSharePosition"></edit-position>
    </div>
</template>

<script>
import sharePosition from './share';
import permission from '@/common/permission';
import {reqGetSharePosition, reqJoinSharePosition, reqDeletePosition} from "@/api/admin-api";
import {reqGetDictionaryListByCode} from "@/api/system-api";
import ViewPosition from "@/views/position/mgt/view";
import markPosition from './mark';
import editPosition from "@/views/position/mgt/edit";

export default {
    name: 'shareList',
    components: {ViewPosition, markPosition, sharePosition, editPosition},
    data() {
        return {
            id: '',
            custId: '',
            position: null,
            customerList: [],
            typeList: [],
            levelList: [],
            statusList: [],
            showShareModel: false,
            showViewModel: false,
            showMarkModel: false,
            showEditModel: false,
            filter: {
                keywords: '',
                type: null,
                customerId: null,
                status: null,
                level: null,
            },
            loading: false,
            dataList: [],
            permissionButton: {
                view: false,
                mark: false,
                join: false,
                manage: false,
                edit: false,
                del: false
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: 'ID',
                    key: 'custId',
                    width: 100,
                    className: 'position-id',
                },
                {
                    title: '职位名称',
                    key: 'name',
                    tooltip: 'true',
                    minWidth: 300,
                },
                {
                    title: '状态',
                    key: 'statusName',
                    width: 100,
                },
                {
                    title: '顾问',
                    key: 'consultantNames',
                    tooltip: 'true',
                    minWidth: 200,
                },
                {
                    title: '客户名称',
                    key: 'customerName',
                    tooltip: 'true',
                    minWidth: 300,
                },
                {
                    title: '所在地区',
                    key: 'areaName',
                    tooltip: 'true',
                    minWidth: 200,
                },
                {
                    title: '联系人',
                    key: 'contact',
                    width: 100,
                    render: (h, params) => {
                        return h('span' , params.row.contact.slice(0,1).concat('**'))
                    },
                },
                {
                    title: '联系人电话',
                    key: 'contactPhone',
                    width: 150,
                    render: (h, params) => {
                        return h('span', params.row.contactPhone.slice(0,3).concat('****',params.row.contactPhone.slice(7)))
                    },
                },
                {
                    title: '类型',
                    key: 'typeName',
                    width: 80,
                },
                {
                    title: '需求金额(元)',
                    minWidth: 120,
                    render:(h,params) =>{
                        return h('span',params.row.amount.toFixed(2))
                    }
                },
                {
                    title: '级别',
                    key: 'levelName',
                    minWidth: 80,
                },
                {
                    title: '更新时间',
                    key: 'updatedTime',
                    width: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                },
            ],
        }
    },
    methods: {
        getSharePosition() {
            this.dataList = [];
            this.loading = true;
            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.filter.keywords,
                type: this.filter.type,
                status: this.filter.status,
                level: this.filter.level,
            };
            reqGetSharePosition(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            })
        },
        resetting() {
            this.dataList = [];
            this.loading = true;
            this.filter.keywords= '';
            this.filter.type= null;
            this.filter.customerId= null;
            this.filter.status= null;
            this.filter.level= null;
            let para = {
                pageNum: 1,
                pageSize: 20,
                keywords: this.filter.keywords,
                type: this.filter.type,
                status: this.filter.status,
                level: this.filter.level,
            };
            reqGetSharePosition(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            })
        },
        handleShare(row, index){
            this.position = row;
            this.showShareModel = true;
        },
        handleMark(row, index) {
            this.position = row;
            this.showMarkModel = true;
        },
        handleView(row, index) {
            this.position = row;
            this.showViewModel = true
        },
        handleJoin(row, index) {
            this.$Modal.confirm({
                title: '确认加入',
                content: '确定加入共享职位：' + row.custId + '？此操作不可逆。',
                width: '360',
                onOk: () => {
                    reqJoinSharePosition(row).then((res) => {
                        if (res.data.code === 1) {
                            this.getSharePosition()
                            this.$Message.success('加入成功');
                        } else {
                            this.$Message.error('加入失败');
                        }
                    }).catch(() => {
                        this.$Message.error('加入失败');
                    })
                }
            })
        },
        handleEdit(row, index) {
            this.id = row.id;
            this.custId = row.custId;
            this.showEditModel = true;
        },
        handleDel(row, index) {
            this.$Modal.confirm({
                title: '删除职位',
                content: '确定删除职位 '+ row.custId +'？',
                width: '240',
                onOk: () => {
                    reqDeletePosition({id: row.id}).then((res) => {
                        if (res.data.code === 1) {
                            this.getSharePosition();
                            this.$Message.success('删除成功');
                        } else {
                            this.$Message.error('删除失败');
                        }
                    })
                }
            })
        },
        getTypeList() {
            reqGetDictionaryListByCode({code: 'position_type'}).then((res) => {
                this.typeList = res.data.data
            }).catch(() => {
                this.typeList = []
            })
        },
        getLevelList() {
            reqGetDictionaryListByCode({code: 'position_level'}).then((res) => {
                this.levelList = res.data.data
            }).catch(() => {
                this.levelList = []
            })
        },
        getStatusList() {
            reqGetDictionaryListByCode({code: 'position_status'}).then((res) => {
                this.statusList = res.data.data
            }).catch(() => {
                this.statusList = []
            })
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getSharePosition()
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getSharePosition()
        },
        doSearching() {
            this.pageNum = 1;
            this.getSharePosition()
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId
        permission.getPermissionButton(permissionId, this.permissionButton)
        this.getSharePosition()
        this.getTypeList();
        this.getLevelList();
        this.getStatusList();
    }
}
</script>
