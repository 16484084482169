<template>
    <Modal v-model="showForm" :title="modalTitle" width="640" :mask-closable="false">
        <Form class="plain-form" ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="100">
            <div class="custom-form">
                <div class="divider">职位信息</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="客户">{{ myPosition.customerName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="职位">{{ myPosition.name }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="类型">{{ myPosition.typeName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="级别">{{ myPosition.levelName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="金额">{{ amount }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="顾问">{{ myPosition.consultantNames }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人">{{ myPosition.contact }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="联系电话">{{ myPosition.contactPhone }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人职务">{{ myPosition.contactTitle }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="创建人">{{ myPosition.createByName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="创建时间">{{ myPosition.createTime }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem class="multiline-content" label="职位说明"><div v-html="myPosition.description"></div></FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem class="multiline-content" label="访谈要点"><div v-html="myPosition.brief"></div></FormItem>
                        </Col>
                    </Row>
                </div>

                <div class="divider">职位共享</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem class="plain-item-margin" label="共享用户" prop="consultants">
                                <Select v-model="editForm.consultants" placeholder="请选择" multiple filterable clearable>
                                    <Option v-for="item in consultantArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                </div>
            </div>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqChangeSharePosition, reqGetShareConsultants} from "@/api/admin-api";

export default {
    name: "markPosition",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        position: {}
    },
    data() {
        return {
            amount: null,
            loading: false,
            consultantArray: [],
            showForm: this.value,
            modalTitle: null,
            myPosition: {},
            editForm: {
                id: null,
                custId: null,
                name: null,
                consultants: null,
            },
            editFormRule:{
                consultants: [{message: '请选择共享用户', type:'array', trigger: 'blur'}]
            }
        }
    },
    methods: {
        handleSubmit() {
            this.$Modal.confirm({
                title: '管理共享池',
                content: '确定操作'+ this.position.name+'职位的共享池？',
                width: '240',
                onOk: () => {
                    this.$refs['editForm'].validate((valid) => {
                        if (!valid) {
                            this.loading = false;
                            return false;
                        }
                        reqChangeSharePosition(this.editForm).then((res) => {
                            this.loading = false;
                            if (res.data.code === 1) {
                                this.$Message.success('共享池管理成功');
                                this.$emit('getSharePosition');
                                this.closeModal(false);
                            } else {
                                this.$Message.error('共享池管理失败');
                            }
                        }).catch(()=>{
                            this.loading = false;
                            this.$Message.error('共享池管理失败');
                        })
                    });
                }
            })
        },
        getAvailConsultants() {
            let consultants = this.editForm.consultants.join(',')
            reqGetShareConsultants({consultants:consultants}).then((res) => {
                this.consultantArray = res.data.data || [];
            }).catch(() => {
                this.consultantArray = [];
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.myPosition = this.position;
                this.editForm.id = this.position.id;
                this.editForm.custId = this.position.custId;
                this.editForm.name = this.position.name;
                this.editForm.typeName = this.position.typeName;
                this.editForm.consultants = this.position.consultantIds ? this.position.consultantIds.split(',') : [];
                this.amount = this.myPosition.amount.toFixed(2);
                this.modalTitle = '管理共享池 (ID:' + this.position.custId + ')';
                this.getAvailConsultants();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
