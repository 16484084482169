<template>
    <Modal v-model="showForm" :title="modalTitle" width="640" :mask-closable="false">
        <Form class="plain-form" ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="90">
            <div class="custom-form">
                <div class="divider">职位信息</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="客户">{{ myPosition.customerName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="职位">{{ myPosition.name }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="类型">{{ myPosition.typeName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="级别">{{ myPosition.levelName }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="金额">{{ amount }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="联系人">{{ myPosition.contact }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系电话">{{ myPosition.contactNumber }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="联系人职务">{{ myPosition.contactTitle }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="创建人">{{ myPosition.createByName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="创建时间">{{ myPosition.createTime }}</FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="顾问">{{ myPosition.consultantNames }}</FormItem>
                        </Col>
                    </Row>
                </div>

                <div class="divider">职位状态</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem class="plain-item-margin" label="标记状态" prop="status">
                                <Select v-model="editForm.status" placeholder="请选择" filterable clearable>
                                    <Option v-for="item in statusList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                </div>
            </div>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqUpdatePosition} from "@/api/admin-api";
import {reqGetDictionaryListByCode} from "../../../api/system-api";

export default {
    name: "markPosition",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        position: {}
    },
    data() {
        return {
            amount: null,
            loading: false,
            statusList: [],
            showForm: this.value,
            modalTitle: null,
            myPosition: {},
            editForm: {
                id: null,
                status: null,
            },
            editFormRule:{
                status: [{required: true, message: '请选择状态标记', type: 'number', trigger: 'change'}]
            }
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            this.$refs['editForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }

                reqUpdatePosition(this.editForm).then((res) => {
                    this.loading = false;
                    if (res.data.code === 1) {
                        this.$Message.success('标记成功');
                        this.$emit('getSharePosition');
                        this.closeModal(false);
                    } else {
                        this.$Message.error('标记失败');
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('标记失败');
                })
            });
        },
        getStatusList() {
            reqGetDictionaryListByCode({code: 'position_status'}).then((res) => {
                let myData = [...res.data.data].filter(item => item.name !== '进行中');
                this.statusList = myData;
            }).catch(() => {
                this.statusList = [];
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.myPosition = this.position;
                this.editForm.id = this.position.id;
                this.editForm.status = null;
                this.amount = this.myPosition.amount.toFixed(2);
                this.modalTitle = '编辑职位 (ID:' + this.position.custId + ')';
                this.getStatusList();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
